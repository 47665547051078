import { createGlobalStyle } from 'styled-components';
import './variables.css';

import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { DatePicker, Input, Radio } from 'antd'


import { device } from './Breakpoints';



import fondo from './images/slide-4.jpg'

export const GlobalStyles = createGlobalStyle`
    htlm,body{
      margin:0;
      pading:0;
    }
    body {
        background-color: var(--background-white);
        margin: 0;
        font-family: 'Poppins', sans-serif;
    }
    figure {
        margin: 0;
    }
    h1, h2{
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }
    h2 {
        font-size: 30px;
    }
    h3 {
        font-size: 24px;
        font-weight: 500;
    }
    button {
        font-size: 17px;
    }
    a {
        text-decoration: none;
    }
    ::placeholder {
    font-size: 14px;
    color: var(--cian-color);
  }
    #root {
        margin: auto;
        min-height: 100vh;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: 1fr auto;
    }
    .swal-icon img {
      max-width:100px;
    }
    `;

const ButtonDesign = (props) => `
  background-color: var(--primary-color);
  color: white;
  border: 0;
  height: 50px;
  width: 60%;
  border-radius: 5px;
  display: block;
  margin: 1.5em auto;
  cursor: pointer;
  :hover {
    transition: 0.5s;
    background: #933e35;
  }
`;


//Components for logo (figure and image)
export const LogoContainer = styled.figure`
  margin: auto;
`;

export const LogoImage = styled.img`
  max-width: ${(props) => props.maxWidth || '200px'};
  height: auto;
`;

// globals buttons for any component
export const Button = styled.button`
  ${ButtonDesign}
`;

export const AnchorButton = styled(Link)`
  ${ButtonDesign}
  margin: ${(props) => props.margin || '1.5em auto;'};
  width: ${(props) => props.width || '60%;'};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// global components for forms (inputs, containers, etc.)
export const LoginRegisterContainer = styled.section`
  grid-row: 1/4;
  display: flex;
`; // This is for the login and register pages

export const Contain = styled.div`
  background-image: url(${fondo});
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  display:flex;
  justify-content: space-between;
 
`;

export const ContentInput = styled.div`
  display:flex;
`;

export const IconInput = styled.span`
  background:red;
`;

export const TextInput = styled.input`
  background: transparent;
  height:40px;
  font-size:20px;
  border: ${(props) => props.border || '1px'} solid var(--primary-color);
`;

export const FormContainer = styled.form`
`;

export const Title = styled.h1`
  color:#D9DAD2;
  font-size:40px;
`;

export const TitleForm = styled.h2`
  color:var(--primary-color);
  width: fit-content;
  margin: 20px auto;
 
`;

export const LabelForm = styled.label`
  font-size: 16px;
  color:var(--secondary-color);
  display: flex;
  flex-direction: column;
  margin: 0 30px;  
`;

export const InputForm = styled(Input)`
  background: transparent;
  height:40px;
  font-size:20px;
  border: ${(props) => props.border || '1px'} solid var(--primary-color);
  & > input {
    background: transparent;
    padding-left:5px;
  }
`;

export const InputTextArea = styled.textarea`
  background: transparent;
  border-color: transparent;
  border-bottom: 1px solid black;
  width: 100%;
  :focus {
    outline: none;
  }
`;

export const AnchorLink = styled(Link)`
  display: block;
  width: fit-content;
  margin: 0 auto 6px;
  color: var(--dark-color);
`;


// Container cards for This is for the login and register pages
export const ContainerCards = styled.div`
  margin: auto 60px;
  border-radius: 15px;
  width:450px;
  height: 90vh;
  background-color: rgba(238,239,234, 0.9);
  display:grid;
  @media ${device.tablet} {
    margin: auto;
  }
  @media ${device.mobileL} {
    margin: 0 auto;
    width:100%;
    background-color: rgba(238,239,234, 0.8);
  }
`;

export const ContainerText = styled.div`
  width:50%;
  @media ${device.tablet} {
    display:none;
  }
`;

//Modals
export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ModalContain = styled.div`
  position: relative;
  background-color: #ffffff;
  padding: 1rem;
  max-width: ${(props) => props.width || '400px;'};
  border-radius: 10px;
`;

export const TitleModal = styled.h2`
  margin-top: 0;
`;

export const CloseModal = styled.span`
  display: block;
  text-align: right;
  cursor: pointer;
  width: fit-content;
  margin-left: auto;
`;

export const InputDate = styled(DatePicker)`
  border: ${(props) => props.border || '1px'} solid var(--primary-color);
  background-color: transparent;
  height:40px;
  & > div {
    & > input{
      padding-left:5px;
    }
  }
`;

export const RadioGroup = styled(Radio.Group)`
  display:flex;
  justify-content: center;
`;

export const RadioInput = styled(Radio)`
  color:var(--secondary-color);
  font-size:16px;
  
`;

export const DatePickerContainer = styled.div`
  display:flex;
  justify-content: space-around;
  @media ${device.mobileL} {
    flex-direction: column;
  }
`;










