import React from 'react'
import Icon from '@ant-design/icons'

const EmailSvg = () => (<svg id="Capa_1" enable-background="new 0 0 511.996 511.996" height="1.3em" viewBox="0 0 511.996 511.996" width="1.3em" xmlns="http://www.w3.org/2000/svg"><path d="m511.996 127.512v-8.079c0-30.419-24.748-55.166-55.166-55.166h-401.664c-30.419 0-55.166 24.747-55.166 55.166v8.079.059 264.995c0 30.417 24.747 55.163 55.166 55.163h401.667c30.417 0 55.163-24.746 55.163-55.163v-264.995c0-.019 0-.039 0-.059zm-456.83-33.245h401.664c13.876 0 25.166 11.289 25.166 25.166v.007l-200.896 133.93c-13.372 8.915-36.83 8.914-50.2.001l-200.9-133.932v-.007c0-13.876 11.289-25.165 25.166-25.165zm401.667 323.462h-401.667c-13.877 0-25.166-11.288-25.166-25.163v-237.072l184.259 122.838c11.704 7.803 26.719 11.703 41.74 11.703 15.017 0 30.039-3.901 41.742-11.703l184.256-122.838v237.072c-.001 13.875-11.289 25.163-25.164 25.163z"/></svg>)

const EmailIcon = (props) => {
    return (
        <Icon component={EmailSvg} {...props} />
    )
}

export default EmailIcon
