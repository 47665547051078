import React, { useState, useEffect } from 'react'
import { Contain, ContainerCards, InputForm, LabelForm, TitleForm, Button, LogoContainer, LogoImage, Title, ContainerText } from '../assets/GlobalStyles'
import { UserOutlined, PhoneOutlined } from '@ant-design/icons'
import Logo from '../assets/images/logo_old2.png'
import EmailIcon from "../components/icons/EmailIcon";
import { v4 as uuidv4 } from 'uuid';
import md5 from 'md5';
import moment from 'moment'
import '../assets/css/booking.css';
import FormBooking from '../components/FormBooking';



const Booking = () => {
    const [formBooking, setFormBooking] = useState({
        checkIn: '',
        checkOut: '',
        numberPersons: '',
        numberCarps: '',
        name: '',
        email: '',
        phone: ''
    });
    const [radioValue, setRadioValue] = useState('carp')
    const [referenceCode, setReferenceCode] = useState()
    const [nextPage, setNextPage] = useState(false)
    const [valorApagar, setValorApagar] = useState(0);
    const [signature, setSignature] = useState();
    const [dateValue, setDateValue] = useState({
        checkIn: undefined,
        checkOut: undefined
    });
    const [enabled, setEnabled] = useState(true)
    const [validatePage1, setValidatePage1] = useState(false)
    const [description, setDescription] = useState()

    useEffect(() => {
        setReferenceCode(`${radioValue}-${uuidv4()}`)
    }, [radioValue])

    useEffect(() => {
        if (dateValue.checkIn && dateValue.checkOut) setEnabled(false);
    }, [dateValue])

    useEffect(() => {
        if (formBooking.numberPersons > 0) {
            let valor = 0;
            const diffDate = moment(dateValue.checkOut.valueOf()).diff(dateValue.checkIn.valueOf(), 'days');
            if (radioValue === 'carp') {
                valor = ((formBooking.numberPersons * 50000) * diffDate);

            } else {
                valor = ((formBooking.numberPersons * 35000) * diffDate);
            }
            setValorApagar(valor)

        } else {
            setValorApagar(0);
        }
    }, [formBooking.numberPersons, dateValue])

    const radioOnchange = e => {
        setRadioValue(e.target.value);

    }

    const disableCheckIn = (current) => {
        const date = moment().add(2, 'days')
        return current && current.valueOf() <= date;
    }

    const disableCheckOut = (current) => {
        const date = !dateValue.checkIn ? moment().add(3, 'days') : moment(dateValue.checkIn.valueOf()).add(1, 'days')
        return current && current.valueOf() < date;
    }

    const handleClick = () => {
        if (formBooking.checkIn === '' || formBooking.checkOut === '' || formBooking.numberPersons === '') {
            setValidatePage1(true);
        } else {
            const encrypMD5 = md5(`${process.env.REACT_APP_API_KEY_PAYU}~${process.env.REACT_APP_MERCHANT_ID}~${referenceCode}~${valorApagar}~COP`);
            setSignature(encrypMD5)
            setDescription("Reserva de " + (radioValue === 'carp' ? 'carpa' : 'espacio') + ", numero de personas " + formBooking.numberPersons + "," + (radioValue === 'carp' ? (" cantidad de carpas " + formBooking.numberCarps) : '') + ", checkin " + formBooking.checkIn + ", checkout " + formBooking.checkOut)
            setNextPage(true)
        }
    }

    const handelCheckInChange = (value, dateString) => {
        setDateValue({ ...dateValue, checkIn: value })
        setFormBooking({
            ...formBooking,
            checkIn: dateString
        })
    }

    const handelCheckOutChange = (value, dateString) => {
        setDateValue({ ...dateValue, checkOut: value })
        setFormBooking({
            ...formBooking,
            checkOut: dateString
        })
    }

    const handleInputChange = (event) => {
        setFormBooking({
            ...formBooking,
            [event.target.name]: event.target.value
        })
    }


    return (
        <Contain>
            {
                !nextPage ?
                    (<ContainerCards >
                        <FormBooking
                            disableCheckIn={disableCheckIn}
                            enabled={enabled}
                            disableCheckOut={disableCheckOut}
                            handelCheckInChange={handelCheckInChange}
                            handelCheckOutChange={handelCheckOutChange}
                            handleClick={handleClick}
                            handleInputChange={handleInputChange}
                            radioOnchange={radioOnchange}
                            radioValue={radioValue}
                            validatePage1={validatePage1}
                            valorApagar={valorApagar}

                        />
                    </ContainerCards>) :
                    (<ContainerCards >
                        <TitleForm>Ingresa tus datos</TitleForm>
                        <LabelForm>
                            Nombre
                            <InputForm name="name" id="name_id" placeholder="Nombre completo" prefix={<UserOutlined />} onChange={handleInputChange} required />
                        </LabelForm>
                        <LabelForm>
                            Correo
                            <InputForm name="email" id="email_id" placeholder="Correo electrónico" prefix={<EmailIcon />} onChange={handleInputChange} required />
                        </LabelForm>
                        <LabelForm>
                            Teléfono
                            <InputForm
                                name="phone"
                                id="phone_id"
                                placeholder="Número de contacto"
                                type="tel"
                                prefix={<PhoneOutlined />}
                                onChange={handleInputChange}
                                required
                            />
                        </LabelForm>

                        <form method="post" action={process.env.REACT_APP_URL_PAYU}>
                            <input name="merchantId" type="hidden" value={process.env.REACT_APP_MERCHANT_ID} />
                            <input name="referenceCode" type="hidden" value={referenceCode} />
                            <input name="description" type="hidden" value={description} />
                            <input name="amount" type="hidden" value={valorApagar} />
                            <input name="tax" type="hidden" value="0" />
                            <input name="taxReturnBase" type="hidden" value="0" />
                            <input name="signature" type="hidden" value={signature} />
                            <input name="accountId" type="hidden" value={process.env.REACT_APP_ACCOUNT_ID} />
                            <input name="currency" type="hidden" value="COP" />
                            <input name="buyerFullName" type="hidden" value={formBooking.name} />
                            <input name="buyerEmail" type="hidden" value={formBooking.email} />
                            <input name="telephone" type="hidden" value={formBooking.phone} />
                            <input name="test" type="hidden" value="0" />
                            <input name="responseUrl" type="hidden" value="" />
                            <input name="confirmationUrl" type="hidden" value="" />
                            <Button name="Submit" type="submit">Pagar</Button>
                        </form>

                    </ContainerCards>)

            }

            <ContainerText className="containerMessage">
                <LogoContainer className="logoCamping">
                    <LogoImage src={Logo} alt="" srcset="" />
                </LogoContainer>
                <Title className="titleMessage">
                    El lugar ideal para descansar y disfrutar de momentos únicos en familia o amigos.
                </Title>
            </ContainerText>
        </Contain>
    )
}

export default Booking
