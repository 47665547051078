import React from 'react'

import { GlobalStyles } from '../assets/GlobalStyles';
import Booking from "../pages/Booking";


function App() {

  return (
    <React.Fragment>
      <GlobalStyles />
      <Booking />
    </React.Fragment>
  )
}

export default App
