import React from 'react'
import Icon from '@ant-design/icons'

const CampingLogo = () => (<svg width="1.5em" height="1.5em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477.753 477.753" id="tent">
    <path d="M146.45 144.362v-27.68a8 8 0 00-16 0v34.16c-4.96 13.84-52.88 146.8-107.52 196.64l-7.36-21.04a8.008 8.008 0 00-15.12 5.28l11.12 32c.139.304.3.598.48.88.198.449.439.878.72 1.28.414.372.871.694 1.36.96l.8.8c.405.254.834.468 1.28.64l.96.48a7.995 7.995 0 002.48 0h174.72a6.264 6.264 0 01-.56-.96l-4.72-15.04-7.36-23.44a6.414 6.414 0 0112.24-3.84l8.56 27.28.64 2 2-2c46.96-44.72 83.6-134.16 104-192.72 1.867-5.44 3.627-10.667 5.28-15.68h-168z"></path>
    <path d="M324.93 153.802l-2.16 6.56c-19.04 55.76-53.76 142.48-99.84 192.64a176.062 176.062 0 01-16.56 16h119.2l-.64-215.2zm149.072 164.892a7.85 7.85 0 00-.912-.492 7.999 7.999 0 00-10.786 3.414l-.014.026-13.44 25.84c-64-68.08-101.92-176.96-108-195.04v46.08l.48 154.48v16h109.6a8.396 8.396 0 002.16 0h.8l1.28-.64.72-.56.96-.88.56-.8.56-.72 18.56-35.68a8 8 0 00-2.528-11.028z"></path>
</svg>
)

const CampingIcon = (props) => {
    return (
        <Icon component={CampingLogo} {...props} />
    )
}

export default CampingIcon
