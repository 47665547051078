import React from 'react'
import { InputDate, InputForm, LabelForm, TitleForm, RadioGroup, RadioInput, Button, DatePickerContainer } from '../assets/GlobalStyles'
import { UsergroupAddOutlined, CalendarFilled } from '@ant-design/icons'
import CampingIcon from "../components/icons/CampingIcon";
import { Typography } from 'antd';
const { Text } = Typography;

const FormBooking = ({
    radioValue,
    radioOnchange,
    handelCheckInChange,
    disableCheckIn,
    handelCheckOutChange,
    formBooking,
    disableCheckOut,
    enabled,
    valorApagar,
    validatePage1,
    handleInputChange,
    handleClick
}) => {
    return (
        <>
            <TitleForm>Haz tu reserva</TitleForm>
            <RadioGroup onChange={radioOnchange} value={radioValue}>
                <RadioInput value={'carp'}>Reservar capa</RadioInput>
                <RadioInput value={'space'}>Llevo mi propia carpa</RadioInput>
            </RadioGroup>

            <DatePickerContainer>
                <LabelForm  >
                    Día de llegada
                    <InputDate
                        className={validatePage1 && formBooking.checkIn === '' ? 'validate' : ''}
                        name='checkIn'
                        suffixIcon={<CalendarFilled />}
                        onChange={handelCheckInChange}
                        disabledDate={disableCheckIn}
                    />
                </LabelForm>
                <LabelForm >
                    Día de salida
                    <InputDate
                        className={validatePage1 && formBooking.checkOut === '' ? 'validate' : ''}
                        name='checkOut'
                        suffixIcon={<CalendarFilled />}
                        onChange={handelCheckOutChange}
                        disabledDate={disableCheckOut} />
                </LabelForm>
            </DatePickerContainer>

            <LabelForm >
                Personas
                <InputForm
                    className={validatePage1 && formBooking.numberPersons === '' ? 'validate' : ''}
                    type="number"
                    name="numberPersons"
                    id="num_people_id"
                    placeholder="Número de personas"
                    prefix={<UsergroupAddOutlined />}
                    onChange={handleInputChange}
                    disabled={enabled} />
            </LabelForm>
            {
                radioValue !== 'carp' || (<LabelForm>
                    Carpas
                    <InputForm
                        type="number"
                        name="numberCarps"
                        id="num_carps_id"
                        placeholder="Número de carpas"
                        prefix={<CampingIcon />}
                        onChange={handleInputChange}
                        disabled={enabled}
                    />
                </LabelForm>)
            }

            {valorApagar !== 0 &&
                <Text className="text" strong>{`Valor a pagar es ${(valorApagar).toLocaleString('en-ES', { style: 'currency', currency: 'USD' })}`}</Text>
            }

            <Button onClick={handleClick}>Reservar</Button>
        </>
    )
}

export default FormBooking